import minBy from "lodash/minBy";
import last from "lodash/last";
export function minByOrThrow(items, selector) {
    var result = minBy(items, selector);
    if (result === undefined) {
        throw new Error("No items");
    }
    return result;
}
export function lastOrThrow(items) {
    var item = last(items);
    if (item === undefined) {
        throw new Error("No items");
    }
    return item;
}
