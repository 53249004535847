var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import MainDetailIcons from "../public/accommodation/MainDetailIcons.vue";
import gql from "graphql-tag";
import getFormattedRentalPerWeek from "../../features/accommodation/getFormattedRentalPerWeek";
import propertyListingPriceFragment from "../../features/accommodation/propertyListingPriceFragment.graphql";
export default Vue.extend({
    components: {
        MainDetailIcons: MainDetailIcons,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
    },
    fragments: {
        property: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment SmallSavedPropertyCard_property on PropertyListing {\n        id\n        propertyName\n        mainThumbnailUrl\n        ...PropertyListingPrice_propertyListing\n        ...MainDetailIcons_property\n      }\n      ", "\n      ", "\n    "], ["\n      fragment SmallSavedPropertyCard_property on PropertyListing {\n        id\n        propertyName\n        mainThumbnailUrl\n        ...PropertyListingPrice_propertyListing\n        ...MainDetailIcons_property\n      }\n      ", "\n      ", "\n    "])), MainDetailIcons.options.fragments.property, propertyListingPriceFragment),
    },
    computed: {
        formattedPrice: function () {
            return getFormattedRentalPerWeek(this.$t.bind(this), this.property);
        },
    },
});
var templateObject_1;
