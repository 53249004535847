import { minByOrThrow } from "../../utils/array";
import flatMap from "lodash/flatMap";
import uniqBy from "lodash/uniqBy";
export function getPropertyListingPrice(property) {
    var allPrices = uniqBy(flatMap(property.roomTypes.map(function (r) { return r.leaseTerms; })).map(function (t) { return t.pricePerWeek; }), function (p) { return p.amount; });
    if (allPrices.length === 0) {
        return undefined;
    }
    if (allPrices.length === 1) {
        return { type: "exact", price: allPrices[0] };
    }
    return {
        type: "from",
        price: minByOrThrow(allPrices, function (p) { return parseFloat(p.amount); }),
    };
}
