import { getPropertyListingPrice } from "./model";
import { moneyFormat } from "../../utils/filters";
export default function getFormattedRentalPerWeek($t, property) {
    var price = getPropertyListingPrice(property);
    if (!price) {
        return undefined;
    }
    var formatted = moneyFormat(price.price);
    if (price.type === "exact") {
        return formatted;
    }
    return "".concat($t("From"), " ").concat(formatted);
}
